// src/components/LocationSelection.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Button,
    Grid,
    Snackbar,
    Alert,
    CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AddLocationIcon from '@mui/icons-material/AddLocation';

// Custom styled components
const StyledCard = styled(Card)(({ theme, selected }) => ({
    cursor: 'pointer',
    borderRadius: '16px',
    overflow: 'hidden',
    boxShadow: selected
        ? `0 4px 20px ${theme.palette.primary.main}`
        : '0 2px 8px rgba(0,0,0,0.1)',
    transform: selected ? 'scale(1.05)' : 'scale(1)',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
        boxShadow: `0 4px 20px ${theme.palette.primary.light}`,
        transform: 'scale(1.05)',
    },
}));

const GradientBackground = styled('div')(({ theme }) => ({
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    height: '140px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const NoLocationsContainer = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: '16px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
}));

function LocationSelection() {
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const mock = urlParams.get('mock');
    const isMock = mock === 'true';

    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [loading, setLoading] = useState(true);

    // Fetch locations from localStorage
    useEffect(() => {
        try {
            const storedLocations = JSON.parse(localStorage.getItem('locations')) || [];
            setLocations(storedLocations);
        } catch (e) {
            console.error('Error parsing locations from localStorage:', e);
            setErrorMessage('Failed to load locations. Please try again.');
            setOpenSnackbar(true);
        } finally {
            setLoading(false);
        }
    }, []);

    const handleNext = () => {
        if (selectedLocation) {
            localStorage.setItem('selectedLocation', JSON.stringify(selectedLocation));
            navigate(`/business-info${isMock ? '?mock=true' : ''}`); // Updated route
        } else {
            setErrorMessage('Please select a location to proceed.');
            setOpenSnackbar(true);
        }
    };

    // const handleConnectWix = () => {
    //     // Logic to connect Wix account
    //     // This could be a redirect to Wix OAuth or any other integration step
    //     console.log('Connect Wix account');
    // };

    if (loading) {
        return (
            <Box sx={{ p: { xs: 2, md: 3 }, textAlign: 'center', mt: 10 }}>
                <CircularProgress />
                <Typography variant="h6" mt={2}>
                    Loading locations...
                </Typography>
            </Box>
        );
    }

    if (locations.length === 0) {
        return (
            <Box sx={{ p: { xs: 2, md: 3 }, justifyContent: 'center', mt: 5 }}>
                <NoLocationsContainer>
                    <SupportAgentIcon color="primary" sx={{ fontSize: 60 }} />
                    <Typography variant="h4" gutterBottom mt={2}>
                        No Locations Found
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Please connect a Wix account that has at least one location.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Follow these steps to connect your Wix account:
                    </Typography>
                    <Box component="ul" sx={{ textAlign: 'left', mx: 'auto', maxWidth: 400 }}>
                        <li>Log in to your Wix account.</li>
                        <li>Ensure you have at least one active location.</li>
                        <li>Restart the Orders.co Connect app installation process.</li>
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddLocationIcon />}
                        href="https://manage.wix.com/"
                        //onClick={handleConnectWix}
                        sx={{ mt: 3 }}
                    >
                    Wix ACCOUNT MANAGER
                    </Button>
                    <Box mt={2}>
                        <Typography variant="body2" color="textSecondary">
                            Need help? Contact support at{' '}
                            <span>855-500-1070 option 4</span>.
                        </Typography>
                    </Box>
                </NoLocationsContainer>
            </Box>
        );
    }

    return (
        <Box sx={{ p: { xs: 2, md: 3 } }}>
            <Box mt={5} textAlign="center">
                <Typography variant="h4" gutterBottom>
                    Welcome to Orders.co Wix Setup
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Select a Location to Connect
                </Typography>
            </Box>
            <Grid container spacing={4} justifyContent="center" mt={3}>
                {locations.map((loc) => (
                    <Grid item xs={12} sm={6} md={4} key={loc.id}>
                        <StyledCard
                            onClick={() => setSelectedLocation(loc)}
                            selected={selectedLocation?.id === loc.id}
                        >
                            <GradientBackground>
                                <LocationOnIcon style={{ fontSize: 60, color: '#fff' }} />
                            </GradientBackground>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    {loc.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {loc.address.formattedAddress}
                                </Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                ))}
            </Grid>
            <Box mt={4} textAlign="right">
                <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={!selectedLocation} // Disable button if no location is selected                
                >
                    Next
                </Button>
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="warning"
                    sx={{ width: '100%' }}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default LocationSelection;
